<template>
  <div class="messagePopup">
    <el-dialog :visible="box_data.dialogVoice" :modal="false" class="dialogVoice">
      <div class="title">选择发音</div>
      <div class="sysContent">
        <el-form ref="form" label-width="30px">
          <el-form-item>
            <el-radio-group v-model="box_data.sysVoices">
              <el-radio label="yg">英式</el-radio>
              <el-radio label="mg">美式</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="dioBtns">
          <div class="btn submitBtn" @click="selectVoice">确 定</div>
        </div>
      </div>
    </el-dialog>
    <div class="mask"></div>
  </div>
</template>
<script>
import { addPersonalConfig } from '@/api/student'
export default {
  props:{
    box_data:{
      type:Object
    }
  },
  data(){
    return{
      sysVoices:'yg'
    }
  },
  methods:{
    selectVoice(){
      this.box_data.dialogVoice = false
      // 只有账号第一次登录会触发
      addPersonalConfig({
        cjr: this.$store.state.userInfo.yhid,
        xgr: this.$store.state.userInfo.yhid,
        xsid: this.$store.state.userInfo.yhid,
        fyms: this.box_data.sysVoices == 'yg' ? 0 : 1,
      }).then(res => {
        localStorage.setItem(`sound_${this.$store.state.userInfo.xm}`, this.box_data.sysVoices)
      }).catch(() => {
      })
    }
  },
  mounted(){
    // dom初始透明度为0，transition 2s后透明度为1
    document.querySelector('.mask').style.opacity = '1'
    document.querySelector('.dialogVoice').style.opacity = '1'
  },
  watch:{

  }
}
</script>
<style lang="scss" scoped>
.mask{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  opacity: 0;
  transition: 2s;
  z-index: 1000;
}
.dialogVoice {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: 2s;
  .title {
    color: #fff;
    position: absolute;
    top: 0;
    font-size: 20px;
    box-sizing: border-box;
    padding: 20px;
    font-weight: bold;
    letter-spacing: 3px;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
  }
  .sysContent {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 70px 90px 60px;
    box-sizing: border-box;
    .dioBtns {
      overflow: hidden;
      padding-top: 30px;
      .btn {
        width: 166px;
        height: 40px;
        float: left;
        text-align: center;
        border: 2px solid #5990FF;
        border-radius: 6px;
        color: #fff;
        font-size: 18px;
        line-height: 40px;
        margin-left: 50px;
        cursor: pointer;
        background: #5990FF;
      }
    }
  }
}
</style>
<style lang="scss">
.messagePopup{
  .dialogVoice {
    .el-dialog {
      width: 500px;
      border-radius: 40px;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 60px 27px 35px;
      background: #5990FF;
      border-radius: 30px;
    }
    .el-radio__label {
      font-size: 30px;
      color: #999;
    }
    .el-radio__inner {
      width: 20px;
      height: 20px;
      top: -4px;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5990FF;
      background: #5990FF;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #5990FF;
    }
  }
}
</style>