<template>
  <el-dialog
    title="修改密码"
    :visible.sync="dialogVisible"
    width="400px"
    :before-close="handleClose"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="formData" :rules="formRules" label-width="100px">
        <el-form-item label="新密码" prop="mm" style="padding-bottom: 5px">
          <el-input v-model="formData.mm" placeholder="请输入新密码"
                    :type="showPassword ? 'text' : 'password'"></el-input>
          <svg-icon :icon="showPassword ? 'icon1' : 'icon2' " @click.native="togglePasswordVisibility"
                    style="position: absolute; top:5px ; right: 10px;display: inline-block"/>
        </el-form-item>
        <el-form-item prop="yzm">
          <el-input placeholder="请输入验证码" style="width:140px;margin-right: 10px" v-model="formData.yzm"
                    clearable></el-input>
          <el-button style="color: orange; border-color: orange;width: 135px" @click="obtainYzm"

                     :disabled="disableButton">
            {{ buttonText }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {sendOnline,  alterPassword} from "@/api/login";
export default {
  data() {
    return {
      dialogVisible: false,
      showPassword: false, // 是否显示密码
      buttonText: '获取验证码',
      disableButton: false,
      yzmResult: '',
      formData: {
        mm: '',
        yzm: '',
        yhlx: 'dcUser',
        yhid:sessionStorage.getItem('yhid'),
        yhm: sessionStorage.getItem('sjh')
      },
      formRules: {
        mm: [
          {required: true, message: '请输入新密码', trigger: 'blur'}
        ],
        yzm: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async obtainYzm() {
      const {resultCode, result} = await sendOnline({phone: this.formData.yhm})
      if (resultCode == 200) {
        this.yzmResult = result
        this.$message.success('获取成功')
      } else {
        this.$message.error('获取失败')
      }

      // 禁用按钮并设置倒计时
      this.disableButton = true;
      let seconds = 60;
      const countDown = setInterval(() => {
        seconds--;
        if (seconds === 0) {
          clearInterval(countDown);
          this.disableButton = false;
          this.buttonText = '获取验证码';
        } else {
          this.buttonText = `${seconds} 秒后重新获取`;
        }
      }, 1000);
    },
    handleSubmit() {
      let param = {
        appIdList: [],
        classIdList: [],
        roleIdList: [],
        studentsIdList: [],
        tenantsIdList: [],
        user: {yhid: sessionStorage.getItem('yhid'), mm: this.formData.mm}
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.yzm === '') {
            return this.$message.error('请输入验证码')
          }
          if (this.formData.yzm != this.yzmResult) {
            return this.$message.error('请输入正确的验证码')
          }
          alterPassword(param).then((res) => {
            if (res.resultCode === 200) {
              this.handleClose()
              this.$message.success('修改成功,请重新登录')
              this.$router.push('/')
            } else {
              this.$message.error('修改失败')
            }
          })
        } else {
          return false;
        }
      })

    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
  }
};
</script>

<style lang="scss" scoped>
.dialog-content {
  text-align: center;
  margin-left: -30px;
}

.dialog-footer {
  padding: 10px;
  text-align: right;
  background-color: #FFFFFF;
}
/deep/ .el-dialog__footer {
  padding: 0;
}
/deep/ .el-dialog__header{
  padding: 15px 0 15px 0;
  text-align: center;
  background-color: #409eff;
}
/deep/ .el-dialog__title{
  text-align: center !important;
  color: #FFFFFF;
}
/deep/ .el-dialog__close{
  color: #FFFFFF !important;
}
</style>
