<template>
  <div>
    <div class="home" ref="home">
      <img class="logo animate__animated animate__bounceInLeft" src="../../assets/images/common/logo.png" alt=""
           width="150">
      <div class="main animate__animated animate__bounceInRight">
        <el-dropdown trigger="click" @command="returnLogin" :hide-on-click="false">
          <div class="el-dropdown-link">
             <svg-icon icon="icon7" v-if="fellow" style="position: relative;top: -15px;left: 15px"></svg-icon>
             <img src="../../assets/images/student/mine.png" alt="">
             <span>
              {{ $store.state.userInfo.xm }} <i class="el-icon-caret-bottom el-icon--right"></i>
                 <span>
            </span>
            </span>
          </div>
          <template>
            <el-dropdown-menu>
              <el-dropdown-item command="20">设置</el-dropdown-item>
              <el-dropdown-item command="myVip" v-if="fellow">我的会员</el-dropdown-item>
              <el-dropdown-item command="goVip" v-else>开通会员</el-dropdown-item>
              <el-dropdown-item command="15">修改密码</el-dropdown-item>
              <!--              <el-dropdown-item command="order">我的订单</el-dropdown-item>-->
              <el-dropdown-item command="10">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="banner"></div>
      <div class="cloud"></div>
      <div class="lists">
        <div class="list" v-for="(data, index) in menulists" :key="data" @click="link(data, index)">
          <img :src='"../../assets/images/student/menuIcon" + (index*1 + 1) +".png"' alt=""
               class="animate__animated animate__bounceIn" :style="'animation-delay:' + (index*0.3+1.5) + 's'">
          <div class="text animate__animated animate__flipInY" :style="'animation-delay:' + (index*0.3+2) + 's'">{{
              data
            }}
          </div>
        </div>
      </div>
    </div>
    <messageBox v-if="box_data.dialogVoice" :box_data="box_data"/>
    <Footer :flag="false"/>
    <Exitpopup :exit_data="exit_data"/>
    <AlterPassword ref="alter"/>
    <Vip ref="membership"/>
    <!--    <Order ref="orderList"/>-->
    <MyVip ref="myVip"/>
  </div>
</template>

<script>
import {isHaveEntranceTest, myPersonalConfig} from '@/api/student'
import messageBox from "@/components/student/messagePopup";
import AlterPassword from '@/components/aboutPassword/alterPassword.vue'
import Vip from '@/views/vip/vip.vue'
// import Order from '@/views/vip/order.vue'
import MyVip from "@/views/vip/myVip.vue";
import {mapState} from "vuex";

export default {
  components: {
    messageBox, AlterPassword, Vip, MyVip,
    Footer: (resolve) => {
      require(['@/components/student/footer.vue'], resolve)
    },
    Exitpopup: (resolve) => {
      require(['@/components/student/exitpopup.vue'], resolve)
    },
  },
  data() {
    return {
      menulists: ['学习之旅', 'AI-单词本', '学情分析', '个人中心'],
      clickAnimate: '',
      box_data: {
        dialogVoice: false,
        sysVoices: 'yg'
      },
      disabled: true,
      jxzt: null, //0集训时间已过, 1集训正在进行
      exit_data: { // 控制退出登录弹窗显示隐藏
        isShow: false
      },
      jx_now_status: null,
    }
  },
  computed: {
    ...mapState(['fellow']),
  },
  mounted() {
    setTimeout(() => {
      this.$refs.home.style.opacity = 1
    }, 10)
    // 查询个性化设置
    myPersonalConfig({xsid: this.$store.state.userInfo.yhid}).then(res => {
      // 返回null，没有新增记录，选择发音弹窗出现，新增
      if (!res.result) {
        setTimeout(() => {
          this.box_data.dialogVoice = true
          this.disabled = false
        }, 2000)
      } else {
        // 返回不为null， 设置过发音模式，拿到接口返回存到本地
        localStorage.setItem(`sound_${this.$store.state.userInfo.xm}`, res.result.fyms ? 'mg' : 'yg')
        this.disabled = false
      }
    })
  },
  methods: {
    // 退出登录
    returnLogin(command) {
      if (command == 10) {
        this.exit_data.isShow = true
      } else if (command == 20) {
        this.$router.replace({path: '/setUpPage/information'})
      } else if (command == "myVip") {
        this.$refs.myVip.dialogVisible = true
        console.log(1111)
      } else if (command === "goVip") {
        this.$refs.membership.drawer = true
      }
          // else if (command === "order") {
          //   this.$refs.orderList.getMyOrder();
          //   this.$refs.orderList.drawer = true
      // }
      else {
        // 修改密码
        this.$refs.alter.dialogVisible = true
      }
    },
    link(data, index) {
      // 弹窗未出现时不可点击五个模块
      if (this.disabled) {
        return
      }

      const element = document.getElementsByClassName('list')[index];
      if (data === '学习之旅') {
        if (this.fellow) {
          element.classList.add('animate__animated', 'animate__zoomOutUp')
          setTimeout(() => {
            const e = document.getElementsByClassName('home')[0];
            e.classList.add('animate__animated', 'animate__fadeOut')
          }, 500)
          this.getIsTest()
        } else {
          this.$message.error('请开通会员来学习')
          setTimeout(() => {
            this.$refs.membership.drawer = true
          }, 1000)
        }

      } else if (data == '学情分析') {
        if (this.fellow) {
          element.classList.add('animate__animated', 'animate__zoomOutUp')
          setTimeout(() => {
            const e = document.getElementsByClassName('home')[0];
            e.classList.add('animate__animated', 'animate__fadeOut')
          }, 500)
          setTimeout(() => {
            this.$router.replace({name: 'secharts'})
          }, 1000);
        } else {
          this.$message.error('请开通会员来学习')
          setTimeout(() => {
            this.$refs.membership.drawer = true
          }, 1000)
        }

      } else if (data == 'AI-单词本') {
        if (this.fellow) {
          element.classList.add('animate__animated', 'animate__zoomOutUp')
          setTimeout(() => {
            const e = document.getElementsByClassName('home')[0];
            e.classList.add('animate__animated', 'animate__fadeOut')
          }, 500)
          setTimeout(() => {
            this.$router.replace({path: '/wordbook/question'})
          }, 1000)
        } else {
          this.$message.error('请开通会员来学习')
          setTimeout(() => {
            this.$refs.membership.drawer = true
          }, 1000)
        }
      } else if (data == '个人中心') {
        element.classList.add('animate__animated', 'animate__zoomOutUp')
        setTimeout(() => {
          const e = document.getElementsByClassName('home')[0];
          e.classList.add('animate__animated', 'animate__fadeOut')
        }, 500)
        // 再调一次，保存集训信息
        setTimeout(() => {
          this.$router.replace({path: '/personalCenter/test'})
        }, 1000);
      } else {
        // this.$emit('clickNoAudio')
        this.$message.warning('暂无内容')
        element.classList.add('animate__animated', 'animate__headShake')
        setTimeout(() => {
          element.className = 'list'
        }, 1000)
      }
    },
    // 判断是否有前后测---前测
    getIsTest() {
      const data = {
        xsid: JSON.parse(sessionStorage.getItem('userInfo')).yhid
      }
      isHaveEntranceTest(data).then(res => {
        if (res.resultDesc == 'SUCCESS') {
          if (!res.result) {
            // 暂无前测 需要前测
            const dataQuery = {
              testType: 1,
            }
            this.$router.replace({name: 'totaltest', query: dataQuery})
          } else {
            setTimeout(() => {
              this.$router.replace({name: 'studyway'})
            }, 1000)
          }
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.el-dropdown-link{
  display: flex;
}
.home {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: 2s;

  .el-icon--right {
    display: none;
  }

  .logo {
    display: block;
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 1;
    animation-delay: 0.6s;
  }

  .main {
    position: absolute;
    top: 40px;
    right: 40px;
    color: #fff;
    font-size: 26px;
    cursor: pointer;
    padding-left: 65px;
    line-height: 50px;
    animation-delay: 0.6s;
    letter-spacing: 2px;
    z-index: 999;

    .el-dropdown {
      img {
        display: block;
        width: 50px;
        height: 50px;
        float: left;
      }

      span {
        display: block;
        float: left;
        margin-left: 10px;
      }

      color: #fff;
      font-size: 24px;
    }
  }

  .banner {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    // animation: moveBanner 10s infinite linear alternate-reverse;
    background-image: url("../../assets/images/student/banner.png");
    // animation-duration: 400s;
    opacity: 0.7;
  }

  .cloud {
    position: absolute;
    // top: -10%;
    top: 100px;
    height: 50%;
    width: 100%;
    background-size: 30% 50%;
    animation: moveCloud 50s infinite linear;
    //动画名称/时长/时间函数/延迟播放时长/播放次数/播放顺序
    background-image: url("../../assets/images/student/cloud.png");
    background-repeat: no-repeat;
    opacity: 0.7;
  }

  .lists {
    width: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .list {
      float: left;
      width: 25%;
      padding: 0 10px;
      box-sizing: border-box;
      cursor: pointer;

      img {
        width: 80%;
        animation-duration: 2s;
        display: block;
        margin: auto;
      }

      .text {
        // color: transparent;
        // -webkit-text-fill-color: #fff;/*文字的填充色*/
        // -webkit-text-stroke: 1.6px #5990ff;
        color: #333;
        font-size: 2vw;
        padding: 40px 0 0;
        font-weight: bold;
        letter-spacing: 5px;
        text-align: center;
      }
    }
  }
}

@keyframes moveBanner {
  100% {
    background-position: 300% 0;
  }
}

@keyframes moveCloud {
  0% {
    background-position-x: -45%;
  }
  100% {
    background-position: 142% 0;
  }
}
</style>



